import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const commitmentData = [
	{
		title: "Diversity",
		description:
			"At Genemod, we believe that diversity means difference. Different social identities, different ways of problem-solving, and different styles of communication. Cultivating diversity also requires a professional culture where all employees can feel comfortable and well-positioned to enact their best work.",
	},
	{
		title: "Inclusion",
		description:
			"Inclusion is spending time understanding those differences and being intentional about creating policies and practices that embrace that diversity. At Genemod, we strive to listen to diverse perspectives and change the way a company does things.",
	},
	{
		title: "Belonging",
		description:
			"For us, a sense of belonging means that people can bring their full selves to work, and not feel like they’re a different person there than at home. We strive to amplify everyone’s voices, clear barriers, and appreciate each other for our unique backgrounds.",
	},
];

export const perksData = [
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/flex-work-hours.svg"
				alt="Flexible work schedule"
			/>
		),
		label: "Flexible work schedule",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/remote-work.svg"
				alt="Remote work program"
			/>
		),
		label: "Remote work program",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/home-office-stipend.svg"
				alt="Home office stipend for employees"
			/>
		),
		label: "Home office stipend for employees",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/conferences-training.svg"
				alt="Job training & conferences"
			/>
		),
		label: "Job training & conferences",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/mentorship-program.svg"
				alt="Mentorship program"
			/>
		),
		label: "Mentorship program",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/401-k.svg"
				alt="401(k) plan"
			/>
		),
		label: "401(k) plan",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/company-equity.svg"
				alt="Company equity"
			/>
		),
		label: "Company equity",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/dental.svg"
				alt="Dental benefits"
			/>
		),
		label: "Dental benefits",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/vision.svg"
				alt="Vision benefits"
			/>
		),
		label: "Vision benefits",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/health-benefits.svg"
				alt="Health insurance benefits"
			/>
		),
		label: "Health insurance benefits",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/beer-on-tap.svg"
				alt="Beer on tap"
			/>
		),
		label: "Beer on tap",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/casual-dress.svg"
				alt="Casual dress"
			/>
		),
		label: "Casual dress",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/happy-hours.svg"
				alt="Happy hours"
			/>
		),
		label: "Happy hours",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/paid-holidays.svg"
				alt="Paid holiday"
			/>
		),
		label: "Paid holiday",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks/unlimited-pto.svg"
				alt="Unlimited vacation policy"
			/>
		),
		label: "Unlimited vacation policy",
	},
	{
		img: (
			<StaticImage
				loading="eager"
				src="../../assets/icons/Perks//company-outings.svg"
				alt="Company outings"
			/>
		),
		label: "Company outings",
	},
];
