// extracted by mini-css-extract-plugin
export var cardWrapper = "index-module--cardWrapper--E+Z6u";
export var careersHeroButton = "index-module--careersHeroButton--gPz1r";
export var careersHeroContainer = "index-module--careersHeroContainer--R+bzh";
export var commitmentCard = "index-module--commitmentCard--HHnCI";
export var commitmentCardContent = "index-module--commitmentCardContent--gdL4Q";
export var jobCard = "index-module--jobCard--DPNI5";
export var jobPosition = "index-module--jobPosition--6k+lY";
export var jobTitleAndPos = "index-module--jobTitleAndPos--fT3QL";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--aiq2g";
export var perkCard = "index-module--perkCard--B+5pk";