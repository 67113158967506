import React from "react";
import cn from "classnames";
import * as styles from "./BackgroundShapes.module.scss";

type ShapeOptions = {
	shape: JSX.Element;
	xOffset?: number;
	yOffset?: number;
};

type BackgroundShapesProps = {
	leftShape?: ShapeOptions;
	rightShape?: ShapeOptions;
	wrapperClassname?: string;
	children?: React.ReactNode;
	hideLtDesktop?: boolean;
	style?: React.CSSProperties;
};

export const BackgroundShapes = ({
	leftShape,
	rightShape,
	wrapperClassname,
	children,
	hideLtDesktop = true,
	style,
}: BackgroundShapesProps) => (
	<div className={cn(styles.wrapper, wrapperClassname)} style={style}>
		<div
			className={cn(styles.backgroundShapes, {
				[styles.hideLtDesktop]: hideLtDesktop,
			})}
		>
			{(leftShape && <Shape {...leftShape} />) || <div style={{width: "50%"}}></div>}
			{(rightShape && <Shape {...rightShape} />) || <div style={{width: "50%"}}></div>}
		</div>
		<div className={styles.children}>{children}</div>
	</div>
);

const Shape = ({ shape, xOffset = 0, yOffset = 0 }: ShapeOptions) => (
	<div style={{ transform: `translate(${xOffset}px,${yOffset}px)` }}>
		{shape}
	</div>
);
